<template>
    <div class="add-new-branch">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.branchUpdate')"
            :title="$t('messages.branchUpdate')"
            @submitPressed="submitPressed"
        />
        <resource-form v-if="branch" :resource-object="branch" :resource="$Branches" base-url="/settings/branches"
                       create-message="messages.branchCreated" update-message="messages.branchUpdated" :action="action"
                       @clearAction="action = null"/>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import ResourceForm from '../components/ResourceForm.vue'

export default {
    components: {
        TitleBar,
        ResourceForm
    },
    data() {
        return {
            branch: null,
            action: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const id = this.$route.params.id;
            this.$Branches.getResource({id}).then((response) => {
                this.branch = response.data;
            });
        },
        submitPressed() {
            this.action = "update";
        },
    },
};
</script>
